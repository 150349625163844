import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { HeroOne, BlogOne } from "gatsby-theme-container";

const DefaultHomeTwo = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Home Two" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <HeroOne data={content?.["hero-section"]} />
            <BlogOne
                data={{
                    ...content?.["latest-blog-section"],
                    items: data?.allArticle?.nodes,
                }}
            />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query DefaultHomeTwoQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "freelancer" }) {
            content {
                ...PageContent
            }
        }
        allProject(limit: 8) {
            nodes {
                ...Project01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article01
            }
        }
    }
`;
DefaultHomeTwo.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allProject: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default DefaultHomeTwo;
